import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';

import FormCloseButton from '../../../components/Form/FormCloseButton';
import {
  selectIsJobOrderDetailLoading,
  selectJobOrderDetail,
  selectJobOrderFilters,
  selectorIsJobOrderDetailUpdated,
} from '../../../store/selectors/jobOrdersSelector';
import theme from '../../../theme';
import { getJobOrderDetail, setJobOrderDetail } from '../jobOrdersReducer';

import RosterPanel from './RosterPanel/RosterPanel';
import JobOrderDetailLeft from './JobOrderDetailLeft';

const JobOrderDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const jobOrderFilters = useSelector(selectJobOrderFilters);
  const jobOrderDetail = useSelector(selectJobOrderDetail);
  const isJobOrderDetailLoading = useSelector(selectIsJobOrderDetailLoading);
  const isJobOrderDetailUpdated = useSelector(selectorIsJobOrderDetailUpdated);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getJobOrderDetail({ jobId: id })); // gets jobOrderDetail on mount
    return () => dispatch(setJobOrderDetail({})); // cleans jobOrderDetail on unmount
  }, [dispatch, id]);

  useEffect(() => {
    if (isJobOrderDetailUpdated) {
      dispatch(getJobOrderDetail({ jobId: id }));
    }
  }, [dispatch, isJobOrderDetailUpdated, id]);

  return isJobOrderDetailLoading ? (
    <CircularProgress />
  ) : (
    <Box
      sx={{
        height: '100vh',
        padding: '25px',
        display: 'flex',
        flexDirection: 'column',
        gap: '36px',
      }}
    >
      <FormCloseButton
        onClose={() => navigate(`/jobs/jobsList?${jobOrderFilters}`)}
        message="buttonText.goBack"
      />
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            backgroundColor: `${theme?.components?.detailLeft?.palette?.backgroundColor}`,
            padding: '16px',
            display: 'flex',
            justifyContent: 'center',
            minWidth: '300px',
          }}
        >
          <JobOrderDetailLeft />
        </Grid>

        <Grid item xs={12} md={9} sx={{ padding: 2 }}>
          {jobOrderDetail?.id && <RosterPanel jobOrder={jobOrderDetail} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default JobOrderDetail;
