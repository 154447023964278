import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, InputAdornment, Paper, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

import asyncService from '../../../datahub/asyncService';
import { validateSchema } from '../../../main-deprecated/components/Form/validations';
import selectUser from '../../../main-deprecated/store/selectors/appSelector';
import Search from '../../assets/icons/SearchPrimaryColor.svg';
import theme from '../../theme';
import { PRIMARY_COLOR } from '../../theme/colorConstants';
import { PRIMARY_FONT } from '../../theme/fontConstants';

const SEARCHBAR_BACKGROUND = {
  DARK: 'dark',
  DEFAULT: 'default',
  LIGHT: 'light',
};

const SearchableSelectDropdownFilter = ({
  disabled,
  placeholder,
  optionsAPICallback,
  onValueChange,
  getOptionLabel,
  sx,
  initialValue,
  wrapperSx,
}) => {
  const initialRenderRef = useRef(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [optionValue, setOptionValue] = useState(null);

  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const token = user?.token;
  const refreshToken = user?.refreshToken;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(open && searchTerm === '' && options.length === 0);

  const debounceSearchDelay = 750;
  const searchTimeoutRef = useRef(null);

  const styleForFormControl = {
    '& .MuiOutlinedInput-root': {
      paddingRight: '10px !important',
    },
    '& .MuiInputBase-inputAdornedEnd': {
      background: optionValue && theme?.button?.palette?.primary,
      color: optionValue && 'white',
      height: '6px',
      borderRadius: '6px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px',
    },

    '& .MuiInputBase-input::placeholder': {
      color: theme?.button?.palette?.primary,
      fontFamily: PRIMARY_FONT['400'],
      opacity: 1,
    },
    border: `1px solid ${theme?.searchBoxForFilter?.borderColor?.focus}`,
    borderRadius: '5px',
    justifyContent: 'center',
    height: '28px',
    width: '200px',
  };

  const loadOptions = useCallback(
    (term) => {
      if (disabled) return;
      const { contract, httpMethod, generateBody, route } = optionsAPICallback;

      const requestBody = generateBody(term);
      if (contract && validateSchema(requestBody, contract)?.FORM_ERROR) {
        return;
      }

      setLoading(true);

      asyncService({
        httpMethod,
        route,
        data: requestBody,
        onSuccess: (d) => {
          const apiResultDocuments = d?.data?.documents;
          setOptions(apiResultDocuments);

          if (initialValue && term === '' && initialRenderRef.current) {
            const initialOption = apiResultDocuments.find((option) => option._id === initialValue);
            setOptionValue(initialOption || null);
            initialRenderRef.current = false;
          }

          setLoading(false);
        },
        onError: () => {
          setOptions([]);
          setLoading(false);
        },
        dispatch,
        token,
        refreshToken,
      });
    },
    [dispatch, initialValue, optionsAPICallback, disabled, refreshToken, token]
  );

  useEffect(() => {
    // to set initial value
    if (!disabled && initialRenderRef.current && initialValue) {
      loadOptions('');
    }
  }, [disabled, initialValue, loadOptions]);

  const handleSearchInputChange = useCallback(
    (e) => {
      const { value } = e.target;
      // Clear any existing timeout before setting a new one
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }

      searchTimeoutRef.current = setTimeout(() => {
        setSearchTerm(value);
        loadOptions(value);
      }, debounceSearchDelay);
    },
    [loadOptions]
  );

  const handleOnOptionSelect = useCallback(
    (event, newValue) => {
      if (newValue === null) {
        setOpen(false);
      }
      if (newValue?._id !== optionValue?._id) {
        setOptionValue(newValue);
        onValueChange(newValue?._id);
      }
    },
    [optionValue, onValueChange]
  );

  const handleClear = () => {
    setSearchTerm('');
    setOptionValue(null);
    setOptions([]);
    onValueChange([]);
  };

  return (
    <FormControl sx={{ ...styleForFormControl, ...wrapperSx }}>
      <Autocomplete
        onChange={handleOnOptionSelect}
        value={optionValue}
        disabled={disabled}
        popupIcon={null}
        clearIcon={null}
        noOptionsText="No results found. Try searching for something else."
        loadingText={
          <Grid container justifyContent="space-between">
            <div> Loading results...</div>
            <CircularProgress size={22} />
          </Grid>
        }
        PaperComponent={(props) => (
          <Paper
            {...props}
            style={{
              borderRadius: '16px',
            }}
            placement="auto-start"
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              display: 'flex',
              backgroundColor: selected ? PRIMARY_COLOR['30'] : 'white',
              cursor: 'pointer',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = PRIMARY_COLOR['60'];
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = selected ? PRIMARY_COLOR['30'] : 'white';
            }}
          >
            {getOptionLabel(option)}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={sx}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 16,
                    }}
                    alt="Search"
                    src={Search}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {optionValue ? (
                    <ClearIcon
                      onClick={handleClear}
                      style={{ cursor: 'pointer', width: '12px', marginLeft: '8px' }}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            onChange={handleSearchInputChange}
            onBlur={() => {
              setSearchTerm('');
              setOptions([]);
            }}
          />
        )}
        isOptionEqualToValue={(option, val) => option?._id === val?._id}
        open={open && !disabled}
        onOpen={() => {
          setOpen(true);
          loadOptions(searchTerm);
        }}
        onClose={() => {
          setOpen(false);
          setLoading(false);
        }}
        loading={loading}
        options={options || []}
        getOptionLabel={(option) => getOptionLabel(option) || ''}
      />
    </FormControl>
  );
};

SearchableSelectDropdownFilter.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func,
  optionsAPICallback: PropTypes.shape({
    httpMethod: PropTypes.string,
    route: PropTypes.string,
    generateBody: PropTypes.func,
    contract: PropTypes.shape({}),
  }),
  getOptionLabel: PropTypes.func,
  sx: PropTypes.shape({}),
  initialValue: PropTypes.string,
  wrapperSx: PropTypes.shape({}),
};

export { SearchableSelectDropdownFilter, SEARCHBAR_BACKGROUND };
