import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

import { Box } from '@mui/material';

import { buildFormField } from '../../../../components/Form/formFieldFactory';
import { BACKFILL_VALUES } from '../../constDicts';
import FormSectionHeader from '../CustomComponents/FormSectionHeader';
import { backfillField } from '../FormFieldData/CreateJobFields';

const BackFill = ({ wrapperSx }) => {
  const formState = useFormState();
  const currentFormValues = formState.values;
  return (
    <Box
      sx={{
        width: '100%',
        ...wrapperSx,
      }}
    >
      <FormSectionHeader messageKey="job.create.consistentStaff.unavail" />
      <Box sx={{ display: 'flex' }}>
        {buildFormField({
          ...backfillField(),
          values: currentFormValues,
          initialValue: BACKFILL_VALUES.ALLOW,
        })}
      </Box>
    </Box>
  );
};

BackFill.propTypes = { wrapperSx: PropTypes.shape({}), currentFormValues: PropTypes.shape({}) };

export default BackFill;
