import { createSelector } from 'reselect';

import { groupJobOrdersByStartDate } from '../../views/jobOrders/jobOrderHelpers';

export const selectJobOrdersState = (state) => state?.jobOrdersState;

export const selectIsJobOrdersLoading = createSelector(
  selectJobOrdersState,
  (state) => state.isJobOrdersLoading
);
export const selectIsJobOrderDetailLoading = createSelector(
  selectJobOrdersState,
  (state) => state.isJobOrderDetailLoading
);

export const selectJobOrders = createSelector(selectJobOrdersState, (state) => state.jobOrders);

export const selectCancelledPlacement = createSelector(
  selectJobOrdersState,
  (state) => state.cancelledPlacement
);

export const selectIsLoadingCancelledPlacement = createSelector(
  selectJobOrdersState,
  (state) => state.isLoadingCancelledPlacement
);

export const selectIsCancelledPlacementUpdating = createSelector(
  selectJobOrdersState,
  (state) => state.isCancelledPlacementUpdating
);

export const selectJobOrderDetail = createSelector(
  selectJobOrdersState,
  (state) => state.jobOrderDetail
);

export const selectorIsJobOrderDetailUpdated = createSelector(
  selectJobOrdersState,
  (state) => state.isJobOrderDetailUpdated
);

export const selectTimecardUpdateError = createSelector(
  selectJobOrdersState,
  (state) => state.timecardUpdateError
);
export const selectTimeCardIsUpdating = createSelector(
  selectJobOrdersState,
  (state) => state.timeCardIsUpdating
);

export const selectJobOrdersGroupedByStart = createSelector(selectJobOrders, (jobOrders) =>
  groupJobOrdersByStartDate(jobOrders)
);

export const selectJobOrdersTotalRowCount = createSelector(
  selectJobOrdersState,
  (jobOrders) => jobOrders.totalRowCount
);

export const selectErrorPostGroupOrders = createSelector(
  selectJobOrdersState,
  (jobOrders) => jobOrders.errorPostGroupOrders
);

export const selectPostGroupOrdersResponse = createSelector(
  selectJobOrdersState,
  (jobOrders) => jobOrders.postGroupOrdersResponse
);

export const selectIsProcessingPostGroupOrders = createSelector(
  selectJobOrdersState,
  (jobOrders) => jobOrders.isProcessingPostGroupOrders
);

export const selectIsCancelProcessing = createSelector(
  selectJobOrdersState,
  (jobOrders) => jobOrders.isCancelProcessing
);

export const selectIsCancelSeriesProcessing = createSelector(
  selectJobOrdersState,
  (jobOrders) => jobOrders.isCancelSeriesProcessing
);

export const selectIsUpdateProcessing = createSelector(
  selectJobOrdersState,
  (jobOrders) => jobOrders.isUpdateProcessing
);

export const selectJobOrderFilters = createSelector(
  selectJobOrdersState,
  (jobOrders) => jobOrders.jobOrderFilters
);
