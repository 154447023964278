import {
  epochToDateInReadableFormat,
  epochToDayOfWeek,
  getTimeZone,
  isShiftCompleted,
  isShiftStarted,
} from '../../utils';

import {
  FILTER_FIELDS_TO_OPERATION,
  FILTER_MAPPING,
  SEARCH_PARAM_KEYS,
  SHIFT_OPTION_KEYS,
} from './filterConstants';
import {
  FIVE_AM_IN_MIN,
  JOB_ORDER_TIME_STATUSES,
  NINE_THIRTY_PM_IN_MIN,
  ONE_THIRTY_PM_IN_MIN,
} from './jobOrderConstants';

export const groupJobOrdersByStartDate = (jobOrders) =>
  jobOrders.reduce((acc, curr) => {
    const weekDay = epochToDayOfWeek(curr.start);
    const date = epochToDateInReadableFormat(curr.start);
    if (acc[date]) {
      acc[date].cards.push(curr);
    } else {
      acc[date] = { weekDay, cards: [curr] };
    }
    return acc;
  }, {});

export const getTimeStatus = (start, end) => {
  if (!isShiftStarted(start)) {
    return JOB_ORDER_TIME_STATUSES.UPCOMING;
  }
  if (isShiftCompleted(end)) {
    return JOB_ORDER_TIME_STATUSES.COMPLETED;
  }
  return JOB_ORDER_TIME_STATUSES.ACTIVE;
};

export const getUniqObjectsByKey = (array) => [
  ...new Map(array.map((item) => [item?.key, item])).values(),
];

export const getNewShiftFilters = (filters) => {
  if (filters.length === 1) {
    return filters;
  }
  if (filters.length === 2) {
    const startSum = filters[0].value.start + filters[1].value.start;

    const getValue = () => {
      if (startSum === FIVE_AM_IN_MIN + ONE_THIRTY_PM_IN_MIN) {
        return {
          start: FIVE_AM_IN_MIN,
          end: NINE_THIRTY_PM_IN_MIN,
        };
      }
      if (startSum === FIVE_AM_IN_MIN + NINE_THIRTY_PM_IN_MIN) {
        return {
          start: NINE_THIRTY_PM_IN_MIN,
          end: ONE_THIRTY_PM_IN_MIN,
        };
      }
      if (startSum === NINE_THIRTY_PM_IN_MIN + ONE_THIRTY_PM_IN_MIN) {
        return {
          start: ONE_THIRTY_PM_IN_MIN,
          end: FIVE_AM_IN_MIN,
        };
      }
      return {};
    };

    return [
      {
        field: filters[0].field,
        operation: filters[0].operation,
        value: { ...getValue(), zone: filters[0].value.zone },
      },
    ];
  }
  return [];
};

const buildShiftTimePayload = (filters, start, end) => {
  const shiftPayload = {
    field: FILTER_FIELDS_TO_OPERATION.shift.field,
    operation: FILTER_FIELDS_TO_OPERATION.shift.operation,
    value: { start: parseInt(start, 10), end: parseInt(end, 10), zone: getTimeZone() },
  };
  const removedPrevShifts = filters.filter(
    (f) => f.field !== FILTER_FIELDS_TO_OPERATION.shift.field
  );
  return start && end ? [...removedPrevShifts, shiftPayload] : filters;
};

const buildDateRangePayload = (filters, start, end) => {
  if (!start || !end) return filters;
  const parsedStartDate = parseInt(start, 10);
  const parsedEndDate = parseInt(end, 10);
  const dateRangePayload = [
    {
      value: parsedStartDate,
      field: FILTER_FIELDS_TO_OPERATION.startDate.field,
      operation: FILTER_FIELDS_TO_OPERATION.startDate.operation,
    },
    {
      value: parsedEndDate,
      field: FILTER_FIELDS_TO_OPERATION.endDate.field,
      operation: FILTER_FIELDS_TO_OPERATION.endDate.operation,
    },
  ];

  const removedPrevDateRange = filters.filter(
    (f) =>
      f.field !== FILTER_FIELDS_TO_OPERATION.startDate.field &&
      f.field !== FILTER_FIELDS_TO_OPERATION.endDate.field
  );
  return [...removedPrevDateRange, ...dateRangePayload];
};

export const buildFilterPayloadFromUrl = (searchParams, prevFilters) => {
  const newFilters = Object.entries(FILTER_MAPPING).reduce(
    (acc, [paramKey, { field, operation, convertValue }]) => {
      const paramValue = searchParams.get(paramKey);
      const value = convertValue ? convertValue(paramValue) : paramValue;
      const updatedFilters = acc.filter((filter) => filter.field !== field);
      return value !== null ? [...updatedFilters, { field, operation, value }] : updatedFilters;
    },
    prevFilters
  );

  const shiftStart = searchParams.get(SEARCH_PARAM_KEYS.SHIFT_START);
  const shiftEnd = searchParams.get(SEARCH_PARAM_KEYS.SHIFT_END);
  const shiftsIncluded = buildShiftTimePayload(newFilters, shiftStart, shiftEnd);

  const startDate = searchParams.get(SEARCH_PARAM_KEYS.START_DATE);
  const endDate = searchParams.get(SEARCH_PARAM_KEYS.END_DATE);

  const allFilters = buildDateRangePayload(shiftsIncluded, startDate, endDate);

  return allFilters;
};

const { FIVE_AM_TO_ONE_THIRTY_PM, ONE_THIRTY_PM_TO_NINE_THIRTY_PM, NINE_THIRTY_PM_TO_FIVE_AM } =
  SHIFT_OPTION_KEYS;

const shiftTimeCombinations = {
  [`${FIVE_AM_IN_MIN}-${ONE_THIRTY_PM_IN_MIN}`]: [FIVE_AM_TO_ONE_THIRTY_PM],
  [`${ONE_THIRTY_PM_IN_MIN}-${NINE_THIRTY_PM_IN_MIN}`]: [ONE_THIRTY_PM_TO_NINE_THIRTY_PM],
  [`${NINE_THIRTY_PM_IN_MIN}-${FIVE_AM_IN_MIN}`]: [NINE_THIRTY_PM_TO_FIVE_AM],
  // Combined options
  [`${FIVE_AM_IN_MIN}-${NINE_THIRTY_PM_IN_MIN}`]: [
    FIVE_AM_TO_ONE_THIRTY_PM,
    ONE_THIRTY_PM_TO_NINE_THIRTY_PM,
  ],
  [`${NINE_THIRTY_PM_IN_MIN}-${ONE_THIRTY_PM_IN_MIN}`]: [
    ONE_THIRTY_PM_TO_NINE_THIRTY_PM,
    NINE_THIRTY_PM_TO_FIVE_AM,
  ],
  [`${ONE_THIRTY_PM_IN_MIN}-${FIVE_AM_IN_MIN}`]: [
    FIVE_AM_TO_ONE_THIRTY_PM,
    NINE_THIRTY_PM_TO_FIVE_AM,
  ],
};

export const getInitialSelectedShiftOptions = (shiftStart, shiftEnd) => {
  const key = `${shiftStart}-${shiftEnd}`;
  return shiftTimeCombinations[key] || [];
};
